import { Link } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import styles from './dataList.css';
import axios from 'axios';

// 달력
import {
  getDate,
  getFullYear,
  getMonth,
  getYear,
  setHours,
  setMinutes,
} from 'date-fns';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';

// 엑셀 다운로드
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

const DataList = () => {
  ////////////////////////////////////////////////
  // useState 선언
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isSelected, setIsSelected] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [dataGap, setDataGap] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isXlsxReady, setIsXlsxReady] = useState(false);
  ////////////////////////////////////////////////

  // 시작일 선택
  const isPossibleStartDate = (date) => {
    const startDateLimit = new Date(2022, 4, 1); // 2022년 5월 1일 이전의 날짜는 선택 불가능
    const curDate = new Date();
    curDate.setHours(0, 0, 0, 0); // 오늘의 시간을 00:00:00으로 설정
    return date >= startDateLimit && date <= curDate;
  };

  // 종료일 선택
  const isPossibleDay = (date) => {
    const curDate = new Date();
    const selectedDate = new Date(date);

    /* 1000 * 60 * 60 * 24 => 밀리 초에서 일 단위로 변환하는 계산
    1000은 1초를 밀리초로 변환
    60은 1분을 초로 변환
    60은 1시간을 분으로 변환
    24는 1일을 시간으로 변환 */
    const diffInDays = Math.floor(
      (selectedDate - startDate) / (1000 * 60 * 60 * 24)
    );
    return (
      startDate <= selectedDate && selectedDate <= curDate && diffInDays <= 28 // start, end 데이트가 합쳐져서 -2
    );
  };

  const formatDateTime = (date) => {
    if (!date) {
      return '';
    }
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < '10') {
      month = '0' + month;
    }
    let day = date.getDate();
    if (day < '10') {
      day = '0' + day;
    }
    return String(year) + String(month) + String(day);
  };

  // 데이터 형식
  const startDateVal = formatDateTime(startDate);
  const endDateVal = formatDateTime(endDate);

  const formatDate = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    return `${year}.${month}.${day}`;
  };

  const formatTime = (datetime) => {
    const hours = datetime.slice(0, 2);
    const minutes = datetime.slice(2, 4);
    return `${hours}:${minutes}`;
  };

  const floatFormat = (num) => Math.floor(num * 10) / 10;

  const intFormat = (num) => Math.floor(num);

  //  시작일 선택하지 않으면 종료일 안되도록
  const onSelect = (date) => {
    setStartDate(date);
    setIsSelected(true);
    setEndDate(date);
  };

  // 테이블 다르게 가져오기
  const tableThArray = [
    [
      '날짜',
      '관측시각',
      '기온',
      '습도',
      '기압',
      '평균풍속',
      '최대풍속',
      '풍향',
      '일사량',
      '패널온도',
    ],
    ['날짜', '관측시각', '기온', '습도', '풍속', '풍향', '강수량'],
  ];

  // 지점코드 선택
  const changeArea = (e) => {
    setAreaCode(e.target.value);
    setSelectedArea(e.target.value);
    setTableData([]); // 테이블 데이터 초기화
  };

  const getTableHeader = () => {
    if (selectedArea === 'CSite') {
      return tableThArray[0].map((item, index) => <th key={index}>{item}</th>);
    } else if (selectedArea === 'GSite') {
      return tableThArray[0].map((item, index) => <th key={index}>{item}</th>);
    } else if (selectedArea === 'AWS744Site') {
      return tableThArray[1].map((item, index) => <th key={index}>{item}</th>);
    }
    return null;
  };

  // 자료간격 선택
  const changeDataGap = (e) => {
    setDataGap(e.target.value);
  };

  // 테이블 데이터 mapping
  const getTableCells = (tableData) => {
    if (selectedArea === 'CSite') {
      return tableData.map((item, index) => (
        <tr key={index}>
          <td>{formatDate(item.base_date)}</td>
          <td>{formatTime(item.base_time)}</td>
          <td>{floatFormat(item.tmp)}</td>
          <td>{intFormat(item.reh)}</td>
          <td>{floatFormat(item.ap)}</td>
          <td>{floatFormat(item.wsd)}</td>
          <td>{floatFormat(item.wsd_max)}</td>
          <td>{intFormat(item.vec)}</td>
          <td>{floatFormat(item.wm)}</td>
          <td>{floatFormat(item.panel_tmp)}</td>
        </tr>
      ));
    } else if (selectedArea === 'GSite') {
      return tableData.map((item, index) => (
        <tr key={index}>
          <td>{formatDate(item.base_date)}</td>
          <td>{formatTime(item.base_time)}</td>
          <td>{floatFormat(item.tmp)}</td>
          <td>{intFormat(item.reh)}</td>
          <td>{floatFormat(item.ap)}</td>
          <td>{floatFormat(item.wsd)}</td>
          <td>{floatFormat(item.wsd_max)}</td>
          <td>{intFormat(item.vec)}</td>
          <td>{floatFormat(item.wm)}</td>
          <td>{floatFormat(item.panel_tmp)}</td>
        </tr>
      ));
    } else if (selectedArea === 'AWS744Site') {
      return tableData.map((item, index) => (
        <tr key={index}>
          <td>{formatDate(item.base_date)}</td>
          <td>{formatTime(item.base_time)}</td>
          <td>{floatFormat(item.tmp)}</td>
          <td>{intFormat(item.reh)}</td>
          <td>{floatFormat(item.wsd)}</td>
          <td>{intFormat(item.vec)}</td>
          <td>{floatFormat(item.pcp)}</td>
        </tr>
      ));
    }
    return null;
  };

  // 엑셀 다운로드
  const downExcelBtn = () => {
    if (!isXlsxReady) {
      alert('자료를 조회해주세요');
      return;
    }
    const workbook = xlsx.utils.book_new();

    if (selectedArea === 'CSite') {
      const worksheet = xlsx.utils.aoa_to_sheet([
        tableThArray[0],
        ...tableData.map((item) => [
          item.base_date,
          item.base_time,
          item.tmp,
          item.reh,
          item.ap,
          item.wsd,
          item.wsd_max,
          item.vec,
          item.wm,
          item.panel_tmp,
        ]),
      ]);
      xlsx.utils.book_append_sheet(workbook, worksheet, 'CSite');
    } else if (selectedArea === 'GSite') {
      const worksheet = xlsx.utils.aoa_to_sheet([
        tableThArray[0],
        ...tableData.map((item) => [
          item.base_date,
          item.base_time,
          item.tmp,
          item.reh,
          item.ap,
          item.wsd,
          item.wsd_max,
          item.vec,
          item.wm,
          item.panel_tmp,
        ]),
      ]);
      xlsx.utils.book_append_sheet(workbook, worksheet, 'GSite');
    } else if (selectedArea === 'AWS744Site') {
      const worksheet = xlsx.utils.aoa_to_sheet([
        tableThArray[1],
        ...tableData.map((item) => [
          item.base_date,
          item.base_time,
          item.tmp,
          item.reh,
          item.wsd,
          item.vec,
          item.pcp,
        ]),
      ]);
      xlsx.utils.book_append_sheet(workbook, worksheet, 'AWS744Site');
    }

    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(data, '관측자료 조회.xlsx');
  };

  const handleFormSubmit = async () => {
    if (!areaCode || !dataGap) {
      const errorMsg = `${!areaCode ? '지점코드' : ''}${
        !areaCode && !dataGap ? '와 ' : ''
      }${!dataGap ? '자료간격' : ''}을 선택해주세요.`;
      alert(errorMsg);
      return;
    }
    setIsXlsxReady(true);

    try {
      const response = await axios.get(
        'http://222.239.231.149:8000/energyData/',
        {
          params: {
            start_date: startDateVal,
            end_date: endDateVal,
            area_code: areaCode,
            data_gap: dataGap,
          },
        }
      );
      // console.log(response.data);
      setTableData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='pageMargin'>
      <div className='dataCen'>
        <div
          // onSubmit={handleFormSubmit}
          className='dataSelect'
          // method='POST'
          // action='http://222.239.231.150:8000/energyData/'
        >
          <div>
            시작일:
            <DatePicker
              type='text'
              className='datePicker'
              value={startDate}
              selected={startDate}
              onChange={onSelect} // 내가 선택한 날짜가 표시되도록 하기
              selectsStart
              startDate={startDate}
              endDate={endDate}
              locale={ko} // 한글로 변경
              dateFormat='yyyy-MM-dd (eee)' // 시간포맷 (eee하면 요일까지 표시)
              filterDate={isPossibleStartDate}
              // shouldCloseOnSelect={false} // 선택하면 사라지지 않게 하기
            />
          </div>
          {isSelected || startDate ? ( //시작일 선택하지 않으면 종료일 선택 못하도록 null 값 처리
            <div>
              종료일:
              <DatePicker
                type='text'
                className='datePicker'
                value={endDate}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                filterDate={isPossibleDay}
                dateFormat='yyyy-MM-dd (eee)'
                locale={ko}
                disabled={!startDate}
              />
            </div>
          ) : null}

          {/* 지점코드 선택 */}
          <div>
            지점코드:
            <select
              className='selectorStyle'
              onChange={changeArea}
              value={areaCode}
            >
              <option disabled value=''>
                선택해주세요
              </option>
              <option value='CSite'>C지점</option>
              <option value='GSite'>D지점</option>
              <option value='AWS744Site'>AWS744지점</option>
            </select>
          </div>

          {/* 자료간격 선택 */}
          <div>
            자료간격:
            <select
              className='selectorStyle'
              onChange={changeDataGap}
              value={dataGap}
            >
              <option disabled value=''>
                선택해주세요
              </option>
              <option value='10M'>10분</option>
              <option value='1H'>1시간</option>
            </select>
          </div>

          {/* 자료조회버튼 */}
          <div>
            <br />
            <button
              type='submit'
              onClick={handleFormSubmit}
              className='getTableBtn'
            >
              조회
            </button>
          </div>
        </div>

        {/* 관측자료 조회 멘트 */}
        <p className='dataListComment'>
          * 관측자료 조회는 한 번에
          <span className='max30Comment'> 최대 30일</span>까지 가능합니다.
        </p>

        {/* table */}
        <div className='tableCen'>
          <div>
            <div className='downloadComment'>
              상세 자료는 다운 받기 기능을 이용해주세요.
            </div>
            {/* 엑셀 다운로드 */}
            <button className='downExcelBtn' onClick={downExcelBtn}>
              <img
                src={`${process.env.PUBLIC_URL}/img/excelDownload.png`}
                className='exceldown'
                alt='React'
              />
            </button>
          </div>
        </div>
        {/* 테이블 시작 */}
        <div className='tableDataCen'>
          <div className='tableDataStyle'>
            {tableData && (
              <div>
                <table className='tableStyle'>
                  <thead className='tableHead'>
                    <tr>{getTableHeader()}</tr>
                  </thead>
                  <tbody className='tableBody'>
                    {getTableCells(tableData)}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataList;
