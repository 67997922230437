import React, { useState } from 'react';
import axios from 'axios';
// 차트관련
import { Chart as ChartJS } from 'chart.js/auto'; // 사용은 안하지만 안쓰면 오류남
import './chart.css';
import { Line } from 'react-chartjs-2';

// html2canvas: 선택된 영역만큼 canvas로 변경하여 주는 라이브러리
import html2canvas from 'html2canvas';

// 달력
import {
  getDate,
  getFullYear,
  getMonth,
  getYear,
  setHours,
  setMinutes,
} from 'date-fns';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';

const Chart = () => {
  /////////////////////////////////////////////////////
  // useState 선언
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isSelected, setIsSelected] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [areaCode2, setAreaCode2] = useState('');
  const [observeKey, setObserveKey] = useState('');
  const [observeKey2, setObserveKey2] = useState('');
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [isImgReady, setIsImgReady] = useState(false);
  /////////////////////////////////////////////////////

  // 시작일 선택
  const isPossibleStartDate = (date) => {
    const startDateLimit = new Date(2022, 4, 1);
    const curDate = new Date();
    curDate.setHours(0, 0, 0, 0);
    return date >= startDateLimit && date <= curDate;
  };

  // 종료일 선택
  const isPossibleDate = (date) => {
    const curDate = new Date();
    const selectedDate = new Date(date);

    const diffInDays = Math.floor(
      (selectedDate - startDate) / (1000 * 60 * 60 * 24)
    );
    return (
      startDate <= selectedDate && selectedDate <= curDate && diffInDays <= 8 // start, end 데이트가 합쳐져서 -2
    );
  };

  const formatDateTime = (date) => {
    if (!date) {
      return '';
    }
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < '10') {
      month = '0' + month;
    }
    let day = date.getDate();
    if (day < '10') {
      day = '0' + day;
    }
    return String(year) + String(month) + String(day);
  };

  const startDateVal = formatDateTime(startDate);
  const endDateVal = formatDateTime(endDate);

  const onSelect = (date) => {
    //  시작일 선택하지 않으면 종료일 안되도록
    setStartDate(date);
    setIsSelected(true);
    setEndDate(date);
  };

  /////////////////////////////////////////////////////
  // 그래프1
  // 지점코드 선택
  const changeArea = (e) => {
    setAreaCode(e.target.value);
  };

  const changeArea2 = (e) => {
    setAreaCode2(e.target.value);
  };

  // 관측요소 선택
  const changeKey = (e) => {
    setObserveKey(e.target.value);
  };

  const changeKey2 = (e) => {
    setObserveKey2(e.target.value);
  };

  const optionsC = [
    { value: '', label: '선택해주세요', disabled: true },
    { value: 'tmp', label: '기온' },
    { value: 'reh', label: '습도' },
    { value: 'ap', label: '기압' },
    { value: 'wsd', label: '풍속' },
    { value: 'wsd_max', label: '최대풍속' },
    { value: 'vec', label: '풍향' },
    { value: 'wm', label: '일사량' },
    { value: 'panel_tmp', label: '패널온도' },
  ];

  const optionsD = [
    { value: '', label: '선택해주세요', disabled: true },
    { value: 'tmp', label: '기온' },
    { value: 'reh', label: '습도' },
    { value: 'ap', label: '기압' },
    { value: 'wsd', label: '풍속' },
    { value: 'wsd_max', label: '최대풍속' },
    { value: 'vec', label: '풍향' },
    { value: 'wm', label: '일사량' },
    { value: 'panel_tmp', label: '패널온도' },
  ];

  const options744 = [
    { value: '', label: '선택해주세요', disabled: true },
    { value: 'tmp', label: '기온' },
    { value: 'reh', label: '습도' },
    { value: 'wsd', label: '풍속' },
    { value: 'vec', label: '풍향' },
    { value: 'pcp', label: '강수량' },
  ];

  const getOptions = () => {
    if (areaCode === 'CSite') {
      return optionsC;
    } else if (areaCode === 'GSite') {
      return optionsD;
    } else if (areaCode === 'AWS744Site') {
      return options744;
    } else {
      return [];
    }
  };
  const getOptions2 = () => {
    if (areaCode2 === 'CSite') {
      return optionsC;
    } else if (areaCode2 === 'GSite') {
      return optionsD;
    } else if (areaCode2 === 'AWS744Site') {
      return options744;
    } else {
      return [];
    }
  };

  // /////////////////////////////////////////////////////
  // 차트 이미지 다운로드
  const downImgBtn = (e) => {
    if (!isImgReady) {
      alert('그래프를 조회해주세요');
      return;
    }
    console.log('이미지 다운로드');
    html2canvas(document.getElementById('chartImg')).then((canvas) => {
      onSave(canvas.toDataURL('image/png', 'image-download.png'));
    });

    const onSave = (url, filename) => {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = filename;
      link.click(); // a가 실행됨
      document.body.removeChild(link);
      /* html2canvas 설명 :
      click을 누르면 html2canvas를 통해 'chartImg'영역 불러옴
      canvas.toDataURL을 이용해 변환된 이미지를 url로 가져온 후 a 태그에 추가 */
    };
  };

  /////////////////////////////////////////////////////

  const handleSubmit = async () => {
    if (!areaCode || !observeKey) {
      const errorMsg = `그래프 1의 ${!areaCode ? '지점코드' : ''}${
        !areaCode && !observeKey ? '와 ' : ''
      }${!observeKey ? '관측요소' : ''}을 선택해주세요.`;
      alert(errorMsg);
      return;
    }

    try {
      const response = await axios.get(
        'http://222.239.231.149:8000/energyChart/',
        // '/energyChart/',
        {
          params: {
            start_date: startDateVal,
            end_date: endDateVal,
            area_code: areaCode,
            observe_key: observeKey,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // console.log(response.data);
      setChartData(response.data);
      setShowChart(true);
      setIsImgReady(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit2 = async () => {
    if (!areaCode2 || !observeKey2) {
      const errorMsg = `그래프 2의 ${!areaCode2 ? '지점코드' : ''}${
        !areaCode2 && !observeKey2 ? '와 ' : ''
      }${!observeKey2 ? '관측요소' : ''}을 선택해주세요.`;
      alert(errorMsg);
      return;
    }

    try {
      const response2 = await axios.get(
        'http://222.239.231.149:8000/energyChart2/',
        {
          params: {
            start_date: startDateVal,
            end_date: endDateVal,
            area_code: areaCode2,
            observe_key: observeKey2,
          },
        }
      );

      // console.log(response2.data);
      setChartData2(response2.data);
      setShowChart(true);
      setIsImgReady(true);
    } catch (error) {
      console.log(error);
    }
  };

  // 차트
  const options = {
    spanGaps: true,
    // interaction: {  // 호버 관련: 동일한 위치의 툴팁이 전부 뜸
    //   mode: 'index',
    // },
    grouped: false,
    // x축 값이 같은 애들끼리 그룹화할지를 정하는데요,
    // true 설정 시 해당 x축 값내에서 서로 공간을 나누면서 나란히 보여지게 되고,
    // false 설정 시 각 포인트가 해당 x축 정중앙에 그려지게 되어서 x축 값이 같은 애들끼리 서로 겹쳐지게 됩니다.
    plugins: {
      legend: {
        labels: {
          usePointStyle: false, // 범례 도형: false일 때 기본 직사각형
        },
      },
    },
    scales: {
      // responsive: false,
      y: {
        position: 'left',
        beginAtzero: true,
        grid: {
          // 가로선 설정
          display: false,
        },
        ticks: {
          font: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            size: 10,
            weight: 'bold',
          },
        },
      },
      y_sub: {
        position: 'right',
        beginAtzero: true,
        title: {
          display: true,
          align: 'end',
          color: '#808080',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: 'bold',
          },
          // text: '단위: 배',
        },
        grid: {
          // 가로선 설정
          display: false,
        },
        ticks: {
          font: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            size: 10,
            weight: 'bold',
          },
        },
      },
      x: {
        display: true,
        ticks: {
          minRotation: 0, // x축 값의 회전 각도를 설정할 수 있어요.
          padding: 10, // x축 값의 상하 패딩을 설정할 수 있어요.
          font: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            size: 10,
            weight: 'bold',
          },
        },
        xAxisID: 'x',
      },
    },
  };

  const data = {
    labels: chartData.map((dataPoint) => dataPoint.base_datetime),

    datasets: [
      {
        id: 'y',
        type: 'line',
        label: '그래프1',
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        pointBackgroundColor: '#ff0000',
        data: chartData.map((dataPoint) => ({
          x: dataPoint.base_datetime,
          y: dataPoint[observeKey],
        })),
        borderWidth: 2,
        yAxisID: 'y', // 왼쪽 Y축에 매핑
      },
      {
        id: 'y_sub',
        type: 'line',
        label: '그래프2',
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        pointBackgroundColor: '#0000ff',
        data: chartData2.map((dataPoint) => ({
          x: dataPoint.base_datetime,
          y: dataPoint[observeKey2],
        })),
        borderWidth: 2,
        yAxisID: 'y_sub',
      },
    ],
  };

  return (
    <div className='pageMargin'>
      <div className='chartCen'>
        <div className='chartSelect'>
          <div>
            시작일:
            <DatePicker
              type='text'
              className='datePicker'
              selected={startDate}
              onChange={onSelect}
              value={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              locale={ko}
              dateFormat='yyyy-MM-dd (eee)'
              filterDate={isPossibleStartDate}
            />
          </div>
          {isSelected || startDate ? (
            <div>
              종료일:
              <DatePicker
                type='text'
                className='datePicker'
                selected={endDate}
                value={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                filterDate={isPossibleDate}
                dateFormat='yyyy-MM-dd (eee)'
                locale={ko}
                // disabled={!startDate}
              />
            </div>
          ) : null}
        </div>
        <div className='chartComment'>
          <p>
            * 관측자료 조회는 한 번에
            <span className='max30Comment'> 최대 10일</span>까지 가능합니다.
          </p>
        </div>
        {/* chart Area */}
        <div className='chartArea'>
          <div>
            <div className='chart1Area'>
              <div className='chartName'>그래프1</div>
              <div className='chart1Selector'>
                {/* 지점코드 선택 */}
                <div>
                  <div className='graphLayout'>
                    &nbsp;&nbsp;지점코드 :
                    <select
                      className='chartSelectorStyle'
                      onChange={changeArea}
                      value={areaCode}
                    >
                      <option disabled value=''>
                        선택해주세요
                      </option>
                      <option value='CSite'>C지점</option>
                      <option value='GSite'>D지점</option>
                      <option value='AWS744Site'>AWS744지점</option>
                    </select>
                  </div>
                  {/* 관측요소 선택 */}
                  <div className='graphLayout'>
                    &nbsp;&nbsp;관측요소 :
                    <select
                      className='chartSelectorStyle'
                      onChange={changeKey}
                      value={observeKey}
                    >
                      {getOptions().map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    type='submit'
                    onClick={handleSubmit}
                    className='getChartBtn'
                  >
                    조회
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='chart2Area'>
              <div className='chartName2'>그래프2</div>
              <div className='chart1Selector'>
                {/* 지점코드 선택 */}
                <div>
                  <div className='graphLayout'>
                    &nbsp;&nbsp;지점코드 :
                    <select
                      className='chartSelectorStyle'
                      onChange={changeArea2}
                      value={areaCode2}
                    >
                      <option disabled value=''>
                        선택해주세요
                      </option>
                      <option value='CSite'>C지점</option>
                      <option value='GSite'>D지점</option>
                      <option value='AWS744Site'>AWS744지점</option>
                    </select>
                  </div>
                  {/* 관측요소 선택 */}
                  <div className='graphLayout'>
                    &nbsp;&nbsp;관측요소 :
                    <select
                      className='chartSelectorStyle'
                      onChange={changeKey2}
                      value={observeKey2}
                    >
                      {getOptions2().map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    type='submit'
                    onClick={handleSubmit2}
                    className='getChartBtn'
                  >
                    조회
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: '900px', height: '26px' }}>
          <button className='downImgBtn' onClick={downImgBtn}>
            <img
              src={`${process.env.PUBLIC_URL}/img/imgDownload.png`}
              className='imgDown'
              alt='React'
            />
          </button>
        </div>

        {showChart && (
          <div>
            <div className='chartLayout' id='chartImg'>
              <Line
                className='chartStyle'
                type='line'
                data={data}
                options={options}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
