import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './solar.css';
import './header.css';
import axios from 'axios';
import { min } from 'date-fns';
import useInterval from './interval';

const Solar = () => {
  // -----------------------------------------------
  // useState 변수 선언
  const [tableDataC1H, setTableDataC1H] = useState([]);
  const [tableDataC10M, setTableDataC10M] = useState([]);
  const [tableDataD1H, setTableDataD1H] = useState([]);
  const [tableDataD10M, setTableDataD10M] = useState([]);
  const [tableDataAWS7441H, setTableDataAWS7441H] = useState([]);
  const [tableDataAWS74410M, setTableDataAWS74410M] = useState([]);
  // -----------------------------------------------

  const formatDateTime = (datetime) => {
    const year = datetime.slice(0, 4);
    const month = datetime.slice(4, 6);
    const day = datetime.slice(6, 8);
    const hours = datetime.slice(8, 10);
    const minutes = datetime.slice(10, 12);
    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const formatDate = (datetime) => {
    const year = datetime.slice(0, 4);
    const month = datetime.slice(4, 6);
    const day = datetime.slice(6, 8);
    return `${year}.${month}.${day}`;
  };

  const formatTime = (datetime) => {
    const hours = datetime.slice(0, 2);
    const minutes = datetime.slice(2, 4);
    return `${hours}:${minutes}`;
  };

  function intFormat(data, field) {
    return data.map((item) => {
      return {
        ...item,
        [field]: parseInt(item[field]),
      };
    });
  }

  function floatFormat(data, field, decimalPlaces) {
    return data.map((item) => {
      return {
        ...item,
        [field]: parseFloat(item[field]).toFixed(decimalPlaces),
      };
    });
  }

  const pcpFormat = (num) => Math.floor(num);

  const intC1HVec = intFormat(tableDataC1H, 'vec');
  const intC1HReh = intFormat(tableDataC1H, 'reh');
  const floatC1HTmp = floatFormat(tableDataC1H, 'tmp', 1);
  const floatC1HAp = floatFormat(tableDataC1H, 'ap', 1);
  const floatC1HWsd = floatFormat(tableDataC1H, 'wsd', 1);
  const floatC1HWsdM = floatFormat(tableDataC1H, 'wsd_max', 1);
  const floatC1HWm = floatFormat(tableDataC1H, 'wm', 1);
  const floatC1HPanelTmp = floatFormat(tableDataC1H, 'panel_tmp', 1);

  const floatD1HTmp = floatFormat(tableDataD1H, 'tmp', 1);
  const intD1HReh = intFormat(tableDataD1H, 'reh');
  const floatD1HAp = floatFormat(tableDataD1H, 'ap', 1);
  const floatD1HWsd = floatFormat(tableDataD1H, 'wsd', 1);
  const floatD1HWsdM = floatFormat(tableDataD1H, 'wsd_max', 1);
  const intD1HVec = intFormat(tableDataD1H, 'vec');
  const floatD1HWm = floatFormat(tableDataD1H, 'wm', 1);
  const floatD1HPanelTmp = floatFormat(tableDataD1H, 'panel_tmp', 1);

  const float7441HTmp = floatFormat(tableDataAWS7441H, 'tmp', 1);
  const int7441HReh = intFormat(tableDataAWS7441H, 'reh');
  const float7441HWsd = floatFormat(tableDataAWS7441H, 'wsd', 1);
  const int7441HVec = intFormat(tableDataAWS7441H, 'vec');
  const int7441HPcp = floatFormat(tableDataAWS7441H, 'pcp', 1);

  const intC10MVec = intFormat(tableDataC10M, 'vec');
  const intC10MReh = intFormat(tableDataC10M, 'reh');
  const floatC10MTmp = floatFormat(tableDataC10M, 'tmp', 1);
  const floatC10MAp = floatFormat(tableDataC10M, 'ap', 1);
  const floatC10MWsd = floatFormat(tableDataC10M, 'wsd', 1);
  const floatC10MWsdM = floatFormat(tableDataC10M, 'wsd_max', 1);
  const floatC10MWm = floatFormat(tableDataC10M, 'wm', 1);
  const floatC10MPanelTmp = floatFormat(tableDataC10M, 'panel_tmp', 1);

  const floatD10MTmp = floatFormat(tableDataD10M, 'tmp', 1);
  const intD10MReh = intFormat(tableDataD10M, 'reh');
  const floatD10MAp = floatFormat(tableDataD10M, 'ap', 1);
  const floatD10MWsd = floatFormat(tableDataD10M, 'wsd', 1);
  const floatD10MWsdM = floatFormat(tableDataD10M, 'wsd_max', 1);
  const intD10MVec = intFormat(tableDataD10M, 'vec');
  const floatD10MWm = floatFormat(tableDataD10M, 'wm', 1);
  const floatD10MPanelTmp = floatFormat(tableDataD10M, 'panel_tmp', 1);

  const float74410MTmp = floatFormat(tableDataAWS74410M, 'tmp', 1);
  const int74410MReh = intFormat(tableDataAWS74410M, 'reh');
  const float74410MWsd = floatFormat(tableDataAWS74410M, 'wsd', 1);
  const int74410MVec = intFormat(tableDataAWS74410M, 'vec');
  const int74410MPcp = floatFormat(tableDataAWS74410M, 'pcp', 1);

  useEffect(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainC1H/')
      .then((response) => {
        setTableDataC1H(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useInterval(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainC1H/')
      .then((response) => {
        setTableDataC1H(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 60000);

  useEffect(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainC10M/')
      .then((response) => {
        setTableDataC10M(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useInterval(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainC10M/')
      .then((response) => {
        setTableDataC10M(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 60000);

  useEffect(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainD1H/')
      .then((response) => {
        setTableDataD1H(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useInterval(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainD1H/')
      .then((response) => {
        setTableDataD1H(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 60000);

  useEffect(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainD10M/')
      .then((response) => {
        setTableDataD10M(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useInterval(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainD10M/')
      .then((response) => {
        setTableDataD10M(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 60000);

  useEffect(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainAWS7441H/')
      .then((response) => {
        setTableDataAWS7441H(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useInterval(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainAWS7441H/')
      .then((response) => {
        setTableDataAWS7441H(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 60000);

  useEffect(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainAWS74410M/')
      .then((response) => {
        setTableDataAWS74410M(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useInterval(() => {
    axios
      .get('http://222.239.231.149:8000/energyMainAWS74410M/')
      .then((response) => {
        setTableDataAWS74410M(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 60000);

  return (
    <div>
      <div className='cen'>
        <div className='solarMain'>
          <div className='area1H'>
            <div className='areaTable'>
              <div className='tableInfo'>
                C지점 (1시간 간격)
                {tableDataC1H.length > 0 ? (
                  <div className='tableUpdate'>
                    Update :&nbsp;
                    {formatDateTime(tableDataC1H[0].base_datetime)}
                  </div>
                ) : (
                  <div className='tableUpdate'>
                    데이터를 불러오는 중입니다...
                  </div>
                )}
              </div>
              <div>
                <table className='mTable'>
                  <thead className='mTableHead'>
                    <tr>
                      <th>날짜</th>
                      <th>관측시각</th>
                      <th>기온</th>
                      <th>습도</th>
                      <th>기압</th>
                      <th>평균풍속</th>
                      <th>최대풍속</th>
                      <th>풍향</th>
                      <th>일사량</th>
                      <th>패널온도</th>
                    </tr>
                  </thead>
                  <tbody className='mTableBody'>
                    {tableDataC1H.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDate(item.base_date)}</td>
                        <td>{formatTime(item.base_time)}</td>
                        <td>{floatC1HTmp[index].tmp}</td>
                        <td>{intC1HReh[index].reh}</td>
                        <td>{floatC1HAp[index].ap}</td>
                        <td>{floatC1HWsd[index].wsd}</td>
                        <td>{floatC1HWsdM[index].wsd_max}</td>
                        <td>{intC1HVec[index].vec}</td>
                        <td>{floatC1HWm[index].wm}</td>
                        <td>{floatC1HPanelTmp[index].panel_tmp}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='areaTable'>
              <div className='tableInfo'>
                D지점 (1시간 간격)
                {tableDataD1H.length > 0 ? (
                  <div className='tableUpdate'>
                    Update :&nbsp;
                    {formatDateTime(tableDataD1H[0].base_datetime)}
                  </div>
                ) : (
                  <div className='tableUpdate'>
                    데이터를 불러오는 중입니다...
                  </div>
                )}
              </div>
              <div>
                <table className='mTable'>
                  <thead className='mTableHead'>
                    <tr>
                      <th>날짜</th>
                      <th>관측시각</th>
                      <th>기온</th>
                      <th>습도</th>
                      <th>기압</th>
                      <th>평균풍속</th>
                      <th>최대풍속</th>
                      <th>풍향</th>
                      <th>일사량</th>
                      <th>패널온도</th>
                    </tr>
                  </thead>
                  <tbody className='mTableBody'>
                    {tableDataD1H.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDate(item.base_date)}</td>
                        <td>{formatTime(item.base_time)}</td>
                        <td>{floatD1HTmp[index].tmp}</td>
                        <td>{intD1HReh[index].reh}</td>
                        <td>{floatD1HAp[index].ap}</td>
                        <td>{floatD1HWsd[index].wsd}</td>
                        <td>{floatD1HWsdM[index].wsd_max}</td>
                        <td>{intD1HVec[index].vec}</td>
                        <td>{floatD1HWm[index].wm}</td>
                        <td>{floatD1HPanelTmp[index].panel_tmp}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='areaTable'>
              <div className='tableInfo'>
                AWS744지점 (1시간 간격)
                {tableDataAWS7441H.length > 0 ? (
                  <div className='tableUpdate'>
                    Update :&nbsp;
                    {formatDateTime(tableDataAWS7441H[0].base_datetime)}
                  </div>
                ) : (
                  <div className='tableUpdate'>
                    데이터를 불러오는 중입니다...
                  </div>
                )}
              </div>
              <div>
                <table className='mTable'>
                  <thead className='mTableHead'>
                    <tr>
                      <th>날짜</th>
                      <th>관측시각</th>
                      <th>기온</th>
                      <th>습도</th>
                      <th>풍속</th>
                      <th>풍향</th>
                      <th>강수량</th>
                    </tr>
                  </thead>
                  <tbody className='mTableBody'>
                    {tableDataAWS7441H.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDate(item.base_date)}</td>
                        <td>{formatTime(item.base_time)}</td>
                        <td>{float7441HTmp[index].tmp}</td>
                        <td>{int7441HReh[index].reh}</td>
                        <td>{float7441HWsd[index].wsd}</td>
                        <td>{int7441HVec[index].vec}</td>
                        <td>{pcpFormat(item.pcp)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='area10min'>
            <div className='areaTable'>
              <div className='tableInfo'>
                C지점 (10분 간격)
                {tableDataC10M.length > 0 ? (
                  <div className='tableUpdate'>
                    Update :&nbsp;
                    {formatDateTime(tableDataC10M[0].base_datetime)}
                  </div>
                ) : (
                  <div className='tableUpdate'>
                    데이터를 불러오는 중입니다...
                  </div>
                )}
              </div>
              <table className='mTable'>
                <thead className='mTableHead'>
                  <tr>
                    <th>날짜</th>
                    <th>관측시각</th>
                    <th>기온</th>
                    <th>습도</th>
                    <th>기압</th>
                    <th>평균풍속</th>
                    <th>최대풍속</th>
                    <th>풍향</th>
                    <th>일사량</th>
                    <th>패널온도</th>
                  </tr>
                </thead>
                <tbody className='mTableBody'>
                  {tableDataC10M.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDate(item.base_date)}</td>
                      <td>{formatTime(item.base_time)}</td>
                      <td>{floatC10MTmp[index].tmp}</td>
                      <td>{intC10MReh[index].reh}</td>
                      <td>{floatC10MAp[index].ap}</td>
                      <td>{floatC10MWsd[index].wsd}</td>
                      <td>{floatC10MWsdM[index].wsd_max}</td>
                      <td>{intC10MVec[index].vec}</td>
                      <td>{floatC10MWm[index].wm}</td>
                      <td>{floatC10MPanelTmp[index].panel_tmp}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='areaTable'>
              <div className='tableInfo'>
                D지점 (10분 간격)
                {tableDataD10M.length > 0 ? (
                  <div className='tableUpdate'>
                    Update :&nbsp;
                    {formatDateTime(tableDataD10M[0].base_datetime)}
                  </div>
                ) : (
                  <div className='tableUpdate'>
                    데이터를 불러오는 중입니다...
                  </div>
                )}
              </div>
              <table className='mTable'>
                <thead className='mTableHead'>
                  <tr>
                    <th>날짜</th>
                    <th>관측시각</th>
                    <th>기온</th>
                    <th>습도</th>
                    <th>기압</th>
                    <th>평균풍속</th>
                    <th>최대풍속</th>
                    <th>풍향</th>
                    <th>일사량</th>
                    <th>패널온도</th>
                  </tr>
                </thead>
                <tbody className='mTableBody'>
                  {tableDataD10M.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDate(item.base_date)}</td>
                      <td>{formatTime(item.base_time)}</td>
                      <td>{floatD10MTmp[index].tmp}</td>
                      <td>{intD10MReh[index].reh}</td>
                      <td>{floatD10MAp[index].ap}</td>
                      <td>{floatD10MWsd[index].wsd}</td>
                      <td>{floatD10MWsdM[index].wsd_max}</td>
                      <td>{intD10MVec[index].vec}</td>
                      <td>{floatD10MWm[index].wm}</td>
                      <td>{floatD10MPanelTmp[index].panel_tmp}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='areaTable'>
              <div className='tableInfo'>
                AWS744지점 (10분 간격)
                {tableDataAWS74410M.length > 0 ? (
                  <div className='tableUpdate'>
                    Update :&nbsp;
                    {formatDateTime(tableDataAWS74410M[0].base_datetime)}
                  </div>
                ) : (
                  <div className='tableUpdate'>
                    데이터를 불러오는 중입니다...
                  </div>
                )}
              </div>
              <table className='mTable'>
                <thead className='mTableHead'>
                  <tr>
                    <th>날짜</th>
                    <th>관측시각</th>
                    <th>기온</th>
                    <th>습도</th>
                    <th>풍속</th>
                    <th>풍향</th>
                    <th>강수량</th>
                  </tr>
                </thead>
                <tbody className='mTableBody'>
                  {tableDataAWS74410M.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDate(item.base_date)}</td>
                      <td>{formatTime(item.base_time)}</td>
                      <td>{float74410MTmp[index].tmp}</td>
                      <td>{int74410MReh[index].reh}</td>
                      <td>{float74410MWsd[index].wsd}</td>
                      <td>{int74410MVec[index].vec}</td>
                      <td>{pcpFormat(item.pcp)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solar;
