import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import './header.css';

const Header = () => {
  const [currentTime, setCurrentTime] = useState('');
  const locatation = useLocation(); // 현재 경로 정보 가져오기

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const formattedTime = format(now, 'yyyy-MM-dd HH:mm:ss');
      setCurrentTime(formattedTime);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='header'>
      <div className='nav'>
        {/* 로고 */}
        <div className='navLogo'>
          <div className='logo'>
            <img
              src={`${process.env.PUBLIC_URL}/img/logo.png`}
              className='logoImg'
              alt='React'
            />
          </div>
        </div>
        {/* 메뉴바 */}
        <div className='navMenu'>
          <div className='curTimeStamp'>{currentTime}</div>
          <div className='navList'>
            <Link
              className={`menuName ${locatation.pathname === '/' && 'active'}`}
              to='/'
            >
              실시간 모니터링
            </Link>
            <Link
              className={`menuName ${
                locatation.pathname === '/dataList' && 'active'
              }`}
              to='/dataList'
            >
              관측자료 조회
            </Link>
            <Link
              className={`menuName ${
                locatation.pathname === '/chart' && 'active'
              }`}
              to='/chart'
            >
              그래프 표출
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
