import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './header/header.js';
import Solar from './header/solar.js';
import DataList from './dataList/dataList.js';
import Chart from './chart/chart.js';
import { useState } from 'react';

function App() {
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <div className='App'>
      <Header />
      <Routes>
        <Route path='/' element={<Solar />} />
        <Route
          path='/dataList'
          element={
            <DataList
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          }
        />
        <Route path='/chart' element={<Chart />} />
      </Routes>
    </div>
  );
}

export default App;
